<template>
  <v-row
    align="center"
    justify="center">
    <v-col
      cols="12"
      sm="8">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <span>
            {{ $t('crudActions.indexModel', { model: $t('models.accountableEntities.multiple') }) }}
          </span>
          <v-btn
            small
            color="primary"
            :to="{ name: $routes.accountableEntity.createRoute.name }">
            {{ $t('crudActions.createModel', { model: $t('models.accountableEntities.single') }) }}
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :loading="loading"
            :headers="tableColumns"
            mobile-breakpoint="400"
            :items="accountableEntities">
            <template #item.action="{ item }">
              <v-btn
                icon
                @click="editAccountableEntity(item)">
                <font-awesome-icon icon="pencil-alt" />
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { fetchAccountableEntities } from "../../api/accountableEntity.js";
import ContentLoader from "../../mixins/ContentLoader.js";
import { i18n } from "../../i18n";

export default {
	name: "AccountableEntityIndex",
	mixins: [ContentLoader],
	data() {
		return {
			accountableEntities: [],
		};
	},
	computed: {
		tableColumns() {
			return [
				{
					value: "id",
					text: "#",
				},
				{
					value: "name",
					text: i18n.t("attributes.accountableEntities.name"),
				},
				{
					value: "code",
					text: i18n.t("attributes.accountableEntities.code"),
				},
				{
					value: "action",
					text: i18n.t("actions.text"),
				},
			];
		},
	},
	async mounted() {
		this.$startLoading();

		try {
			const {
				data: { accountableEntities },
			} = await fetchAccountableEntities();

			this.accountableEntities = accountableEntities;

			await this.$nextTick();
		} finally {
			this.$finishedLoading();
		}
	},
	methods: {
		editAccountableEntity({ id }) {
			this.$router.push({
				name: this.$routes.accountableEntity.editRoute.name,
				params: { id },
			});
		},
	},
};
</script>
